export const transitionEndEventName = detectTransitionEndEventName();

export function detectTransitionEndEventName() {

    const transitions = {
        "transition": "transitionend",
        "OTransition": "oTransitionEnd",
        "MozTransition": "transitionend",
        "WebkitTransition": "webkitTransitionEnd"
    };

    const el = document.createElement("div");

    for (const t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
    return "";
}

export function isVisible (elem) {
    return !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
}

export function cssAnimateNewHeight(container, classTarget = container, classOnBoth = false) {
    let newHeight;
    const prevHeight = container.offsetHeight;

    const delayedClose = () => {
        if (classOnBoth) {
            container.classList.remove('open');
            classTarget.classList.remove('open');
        } else {
            classTarget.classList.remove('open');
        }

        classTarget.blur(); // removes focus if its a button
        container.removeEventListener(transitionEndEventName, delayedClose);
    };

    container.removeAttribute('style');

    if (classTarget.classList.contains('open')) {
        newHeight = 0;
        container.addEventListener(transitionEndEventName, delayedClose);

        if (classOnBoth) {
            classTarget.classList.remove('open');
        }
    } else {
        classTarget.classList.add('open');

        if (classOnBoth) {
            container.classList.add('open');
        }

        newHeight = container.offsetHeight;
    }

    container.style.height = `${prevHeight}px`;

    setTimeout(() => {
        container.style.height = `${newHeight}px`;
    }, 10);
}

export function setupReadmore(selector = '[data-action*="readmore"]') {
    const readMoreBtns = document.body.querySelectorAll(selector);

    for (let i = 0; i < readMoreBtns.length; i++) {
        const readMoreBtn = readMoreBtns[i];
        const readMoreContainer = readMoreBtn.hasAttribute('data-target') ? document.getElementById(readMoreBtn.getAttribute('data-target').replace('#', '')) : readMoreBtn.nextElementSibling;

        if (readMoreContainer != null) {
            readMoreBtn.addEventListener('click', (e) => {
                e.preventDefault();

                cssAnimateNewHeight(readMoreContainer, readMoreBtn, true);
            });
        }

    }
}

export function polyfillClosest() {
    Element.prototype.closest = function (selector) {
        const theparent = this.parentNode;
        const find = document.querySelectorAll(selector);

        if (theparent !== document.body) {
            let found = false;

            for (let a = 0; a < find.length; a++) {
                if (find[a] === theparent) {
                    found = true;
                }
            }

            if (found) {
                return theparent;
            } else {
                return this.parentNode.closest(selector);
            }
        } else {
            return 0;
        }
    };
}

export function scrollTo(element, to, duration) {
    const start = element.scrollTop,
        change = to - start,
        increment = 20;

    const animateScroll = function (elapsedTime) {
        elapsedTime += increment;
        const position = easeInOut(elapsedTime, start, change, duration);
        element.scrollTop = position;

        if (elapsedTime < duration) {
            setTimeout(function () {
                animateScroll(elapsedTime);
            }, increment);
        }
    };

    animateScroll(0);
}

function easeInOut(currentTime, start, change, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) {
        return change / 2 * currentTime * currentTime + start;
    }
    currentTime -= 1;
    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
}

/**
 * Find out whether or not the given argument is an element that would react somewhat normally to DOM-manipulations.
 *
 * @param {*} element - The element to check.
 * @returns {boolean} `true` if the given argument is an element (or document, or window), and `false` otherwise.
 */
export function isElement(element) {
    return element instanceof Element ||
        element instanceof Document ||
        element instanceof Window;
}
