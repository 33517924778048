import {scrollTo} from "../utils/helpers";

export function initAnchorLinkScroll(triggerSelector = '[data-action*="anchor"], a[href^="#"]:not([href="#"])') {
    const triggers = document.querySelectorAll(triggerSelector);

    for (let i = 0; i < triggers.length; i++) {
        const trigger = triggers[i];
        let elementId = '';

        if (trigger.hasAttribute('data-action')) {
            elementId = trigger.getAttribute('data-target');
        } else if (trigger.hasAttribute('href')) {
            elementId = trigger.getAttribute('href');
        }

        const element = document.getElementById(elementId.replace('#', ''));
        if (element) {
            trigger.addEventListener('click', (e => {
                e.preventDefault();

                scrollToElement(element);
            }));
        }
    }
}

export function initScrollByUrlHash () {
    if (window.location.hash) {
        const element = document.getElementById(window.location.hash.replace('#', ''));
        if (element) {
            scrollToElement(element);
        }
    }
}

function scrollToElement (element) {
    scrollTo(document.documentElement, element.offsetTop, 1000);
    scrollTo(document.body, element.offsetTop, 1000);
}
