
export function change() {


    const options = {
        root: document.body, // The document viewport (default)
        rootMargin: '0px', // No offset from the root element (default)
        threshold: 1.0, // Full intersection into the root element
    };

    const stickyContainers = document.querySelectorAll('.bg__change');
    const io_options = {
        root: document.html,
        rootMargin: '0px 0px -90% 0px',
        threshold: 0
    };
    const io_observer = new IntersectionObserver(io_callback, io_options);



    function io_callback(entries, observer) {
        entries.forEach(entry => {

            if (entry.isIntersecting) {
                // console.log(entry.target.getAttribute('data-bg-color'));
                if (entry.target.getAttribute('data-bg-color') == 'teal') {
                    // console.log('add');
                        document.body.classList.add('l__teal');
                }
                else {
                    // console.log('remove');
                    document.body.classList.remove('l__teal');

                }
            }
        });
    }

    stickyContainers.forEach(element => {
        io_observer.observe(element);
    });

}
