import { setupReadmore } from './utils/helpers';
import { onReady } from "./utils/onReady";
import { initScrollHeader } from "./utils/scrollPosition";
// import {setupBgPlayButtons} from "./modules/video";
// import {setupFilters} from "./modules/filter";
import * as navigation from "./modules/navigation";
import * as faq from "./modules/faq";
import * as logo from "./modules/logo";
// import Rellax from "rellax";
import sal from 'sal.js';
// import * as form from './modules/form';
import { onWindowResize, currentWindowWidth, initWindowResize } from './utils/windowResize';
import { initAnchorLinkScroll, initScrollByUrlHash } from './modules/anchors';
import { initRellaxJs } from './modules/rellax';
import "intersection-observer";


onReady(() => {
    initWindowResize();

    setTimeout(() => {
        document.body.classList.remove("loading");
    }, 250);

    // setupBgPlayButtons();
    // setupReadmore();
    // setupFilters();
    navigation.init();

    logo.change();
   
   
    faq.initFaq();


    initScrollHeader;

    // form.init();
    sal({
        threshold: .2,
    });
    initAnchorLinkScroll();
    initScrollByUrlHash();
    initRellaxJs();
});
