import {activityThrottler} from "../utils/trottle"

//use window.scrollY
var scrollpos = window.scrollY;
var header = document.getElementById("header");

function add_class_on_scroll() {
    header.classList.add("fade-in");
}

function remove_class_on_scroll() {
    header.classList.remove("fade-in");
}


function scrollEvent() {
  //Here you forgot to update the value
  scrollpos = window.scrollY;

  if(scrollpos > 10){
      add_class_on_scroll();
  }
  else {
      remove_class_on_scroll();
  } 
  console.log(scrollpos);
};

export function initScrollHeader(){
    window.addEventListener('scroll', activityThrottler(500,scrollEvent()));
} 